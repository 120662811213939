.App {
  display: flex;
  justify-content: center;
  font-size: 4rem;
  margin-top: 8rem;
  animation: spin infinite 20s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
